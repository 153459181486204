/* eslint-disable no-undef */
import React from "react"
import TemplatePageMarcadorProgresivo from "@components/pageFunctionalities/pageMarcadorProgresivo/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Automatiza tus llamadas con nuestro Marcador Progresivo [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/marcador-progresivo/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Conoce cómo nuestro Marcador Progresivo te ayudará a mejorar todas tus campañas de llamadas salientes de tu Contact Center."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Automatiza tus llamadas con nuestro Marcador Progresivo"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/marcador-progresivo/`}
      />
       <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Conoce cómo nuestro Marcador Progresivo te ayudará a mejorar todas tus campañas de llamadas salientes de tu Contact Center."
      />
    </Helmet>
    <TemplatePageMarcadorProgresivo location={location}/>
  </div>
)

export default IndexPage
