import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="153" height="9" viewBox="0 0 153 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.4451 9C25.2819 8.16452 2.12012 9.09727 0.436587 8.84548C-0.937862 8.50786 1.25863 2.5622 2.53443 1.68666C2.98162 1.37192 3.50115 1.32616 4.69146 1.46922C6.57886 1.73818 30.9835 1.20598 30.9835 1.20598C31.3058 1.38909 32.2725 0.77107 32.4501 0.994246C33.009 1.51499 72.5655 0.805402 75.9392 0.77679C77.9844 0.759622 76.3667 1.11441 78.7736 0.971352C83.9031 0.685228 95.0828 0.959909 98.9694 0.633728C102.08 0.364771 102.751 0.353335 105.736 0.570789C108.788 0.765353 125.012 0.484949 126.866 0.0672084C127.32 -0.0472411 127.668 -0.00718032 127.668 0.124437C127.668 0.153049 139.105 0.250328 139.157 0.353332C139.243 0.484949 139.591 0.547892 139.914 0.456332C140.512 0.318993 152.685 -0.178864 152.875 0.204542C153.474 1.30326 151.797 5.98424 150.383 7.12301C148.936 8.3419 140.992 6.97424 134.606 7.70672L104.875 7.78111L98.5025 8.2389C97.7725 7.84405 94.9645 8.83976 93.2678 8.13017C92.551 7.84977 87.4872 7.80971 86.3692 8.10728C85.3959 8.33618 59.4918 7.75821 56.835 8.55363C52.1001 7.88982 32.8972 8.83977 28.4451 9Z" fill="#FFA700" />
    </svg>
  </span>
)
export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="188" height="9" viewBox="0 0 188 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.9521 9C31.0653 8.16452 2.60512 9.09727 0.53646 8.84548C-1.15241 8.50786 1.54655 2.5622 3.1142 1.68666C3.66369 1.37192 4.30206 1.32616 5.76467 1.46922C8.08383 1.73818 38.0713 1.20598 38.0713 1.20598C38.4672 1.38909 39.6551 0.77107 39.8733 0.994246C40.5601 1.51499 89.1655 0.805402 93.3109 0.77679C95.824 0.759622 93.8362 1.11441 96.7937 0.971352C103.097 0.685228 116.834 0.959909 121.61 0.633728C125.432 0.364771 126.256 0.353335 129.925 0.570789C133.674 0.765353 153.609 0.484949 155.888 0.0672084C156.445 -0.0472411 156.874 -0.00718032 156.874 0.124437C156.874 0.153049 170.926 0.250328 170.991 0.353332C171.096 0.484949 171.524 0.547892 171.92 0.456332C172.655 0.318993 187.613 -0.178864 187.847 0.204542C188.582 1.30326 186.522 5.98424 184.784 7.12301C183.007 8.3419 173.245 6.97424 165.399 7.70672L128.866 7.78111L121.036 8.2389C120.139 7.84405 116.688 8.83976 114.604 8.13017C113.723 7.84977 107.501 7.80971 106.127 8.10728C104.931 8.33618 73.1011 7.75821 69.8365 8.55363C64.0184 7.88982 40.4228 8.83977 34.9521 9Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineThree = ({ underline }) => (
  <span>
    {underline}
    <svg width="243" height="9" viewBox="0 0 243 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.1775 9C40.1536 8.16452 3.36725 9.09727 0.693403 8.84548C-1.48955 8.50786 1.99899 2.5622 4.02527 1.68666C4.73551 1.37192 5.56064 1.32616 7.45114 1.46922C10.4488 1.73818 49.2092 1.20598 49.2092 1.20598C49.7209 1.38909 51.2563 0.77107 51.5383 0.994246C52.4261 1.51499 115.251 0.805402 120.609 0.77679C123.858 0.759622 121.288 1.11441 125.111 0.971352C133.258 0.685228 151.014 0.959909 157.187 0.633728C162.127 0.364771 163.192 0.353335 167.934 0.570789C172.781 0.765353 198.548 0.484949 201.493 0.0672084C202.214 -0.0472411 202.768 -0.00718032 202.768 0.124437C202.768 0.153049 220.931 0.250328 221.014 0.353332C221.15 0.484949 221.704 0.547892 222.216 0.456332C223.166 0.318993 242.499 -0.178864 242.802 0.204542C243.753 1.30326 241.089 5.98424 238.844 7.12301C236.546 8.3419 223.929 6.97424 213.787 7.70672L166.566 7.78111L156.445 8.2389C155.286 7.84405 150.826 8.83976 148.131 8.13017C146.993 7.84977 138.95 7.80971 137.175 8.10728C135.629 8.33618 94.4871 7.75821 90.2674 8.55363C82.7472 7.88982 52.2486 8.83977 45.1775 9Z" fill="#FFA700" />
    </svg>
  </span>
)
