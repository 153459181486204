import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="274" height="9" viewBox="0 0 274 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50.7516 8.33102C45.1181 7.42759 3.81313 8.25375 0.802494 7.99818C-1.66075 7.64156 2.12536 1.47805 4.38334 0.568684C5.18161 0.24773 6.11669 0.18235 8.26062 0.354714C9.97121 0.485473 21.7401 0.467639 33.0984 0.402259C44.4567 0.33688 55.4044 0.259614 55.4044 0.259614C55.9746 0.455753 57.7308 -0.180211 58.0501 0.0515895C58.5519 0.324995 76.5244 0.372544 95.1356 0.372544C113.747 0.372544 132.997 0.354711 136.007 0.360654C139.679 0.366598 136.737 0.717274 141.071 0.610289C150.285 0.384432 170.31 0.883691 177.289 0.616229C182.9 0.40226 184.086 0.414147 189.423 0.681609C194.851 0.955015 223.954 0.996621 227.33 0.622174C228.151 0.515189 228.767 0.562737 228.767 0.699439C229.154 0.71727 249.225 1.11549 249.294 1.21653C249.43 1.35324 250.046 1.4305 250.639 1.34135C251.734 1.21059 273.607 1.01445 273.88 1.41862C274.724 2.57762 270.915 7.38004 268.247 8.5331C265.442 9.75154 251.552 8.12894 240.056 8.73518C177.358 8.00412 189.834 8.06356 175.67 8.50338C174.416 8.07544 169.352 9.04425 166.342 8.29536C165.11 7.98629 156.101 7.86742 154.048 8.14677C151.79 8.45584 106.654 7.30277 101.362 8.17054C92.9689 7.40976 58.6887 8.19432 50.7516 8.33102Z" fill="#FFBA00"/>
      </svg>   
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
    width="132" height="9" viewBox="0 0 132 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.4497 8.33102C21.7357 7.42759 1.83698 8.25375 0.386603 7.99818C-0.800069 7.64156 1.0239 1.47805 2.11168 0.568684C2.49625 0.24773 2.94673 0.18235 3.97957 0.354714C4.80365 0.485473 10.4733 0.467639 15.9452 0.402259C21.4171 0.33688 26.6912 0.259614 26.6912 0.259614C26.9659 0.455753 27.8119 -0.180211 27.9658 0.0515895C28.2075 0.324995 36.8658 0.372544 45.8317 0.372544C54.7977 0.372544 64.0713 0.354711 65.5217 0.360654C67.2907 0.366598 65.8733 0.717274 67.961 0.610289C72.4 0.384432 82.0472 0.883691 85.4095 0.616229C88.1125 0.40226 88.6838 0.414147 91.2549 0.681609C93.87 0.955015 107.89 0.996621 109.517 0.622174C109.912 0.515189 110.209 0.562737 110.209 0.699439C110.396 0.71727 120.065 1.11549 120.098 1.21653C120.164 1.35324 120.46 1.4305 120.746 1.34135C121.273 1.21059 131.811 1.01445 131.942 1.41862C132.349 2.57762 130.514 7.38004 129.228 8.5331C127.877 9.75154 121.185 8.12894 115.648 8.73518C85.4424 8.00412 91.4527 8.06356 84.6294 8.50338C84.025 8.07544 81.5857 9.04425 80.1354 8.29536C79.542 7.98629 75.2019 7.86742 74.213 8.14677C73.1252 8.45584 51.3805 7.30277 48.8314 8.17054C44.7879 7.40976 28.2734 8.19432 24.4497 8.33102Z" fill="#FFBA00"/>
      </svg>      
  </span>
)