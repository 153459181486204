/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"


//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/cold-calling-software.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/pronostico-llamadas-call-center.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/proveedores-telefonia-ip.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/auto-dialer-software.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/toip.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageMarcadorProgresivo/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageMarcadorProgresivo/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageMarcadorProgresivo/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageMarcadorProgresivo/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageFunctionalities/pageMarcadorProgresivo/images/accordionLeft/imageFive.webp"


import imageVideo from "@components/sharedComponents/bannerVideo/image/imageProgresivo.jpg"

import { TitleUnderlineMarcadorProgresivoOne, TitleUnderlineMarcadorProgresivoTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineMarcadorProgresivo"
import imageStartMarcadorProgresivo from "@components/pageFunctionalities/components/intro/images/imageStartMarcadorProgresivo.webp"

//iconos poteciamos operaciones
import televentas from "@components/pageHome/operations/images/imageFive.webp"
import telemarketing from "@components/pageHome/operations/images/imageThree.webp"
import cobranzas from "@components/pageHome/operations/images/imageSeven.webp"

// logos testimonios
import CulquiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"

//foto testimonio desktop
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"

//foto testimonio desktop
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"

const titleStart = (
  <p>
    Utiliza marcadores progresivos para <TitleUnderlineMarcadorProgresivoOne underline="automatizar tus" /> <TitleUnderlineMarcadorProgresivoTwo underline="llamadas" />  salientes y aumentar los resultados de conversión
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Maximiza la productividad de tu equipo y eleva las tasas de éxito en tus campañas de llamadas salientes. Ajusta dinámicamente la velocidad de marcación, asegura un flujo constante de llamadas, permitiendo a tus agentes enfocarse en cada llamada.
        <br /><br />
        Utilizamos la inteligencia artificial para optimizar cada interacción, adaptándose a la disponibilidad de tus agentes y a las preferencias de tus leads. Experimenta una mejora significativa en la eficiencia operativa.
      </p>
    ),
    image: imageStartMarcadorProgresivo,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Unifef,
    Auna,
    Alfin,
    Civa,
    G4S,
    Ibr,
    Conexo,
    Eriza,
    Astara,
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Unifef, Auna, Alfin],
    arrayTwo: [Civa, G4S, Ibr, Conexo, Eriza, Astara],
  },
  articles: [
    {
      image: article1,
      title: "14 preguntas para elegir el mejor software de Contact Center",
      textbody:
        "Te contamos qué consideraciones debes tener en cuenta para elegir el mejor software para call center y empieces a mejorar tus campañas.",
      link: "https://beexcc.com/blog/mejor-software-call-center/",
      card: true,
    },
    {
      image: article2,
      title: "Pronóstico de llamadas en call center: ¿Por qué es importante?",
      textbody:
        "Te contamos cuál es la importancia del pronóstico de llamadas en un call center y cuáles son los indicadores que te ayudan a medirlo.",
      link: "https://beexcc.com/blog/pronostico-llamadas-call-center/",
      card: true,
    },
    {
      image: article3,
      title: "7 proveedores de telefonía IP que debes conocer para este 2024",
      textbody:
        "Recopilamos los mejores proveedores de telefonía IP que todo Contact Center debe considerar para el 2024.",
      link: "https://beexcc.com/blog/proveedores-telefonia-ip/",
    },
    {
      image: article4,
      title: "Auto Dialer Software: Top 5 de las plataformas para el 2024",
      textbody:
        "Entérate cuáles son las 5 plataformas que te ofrecen Auto Dialer Software. Así elegirás la mejor para tu empresa.",
      link: "https://beexcc.com/blog/auto-dialer-software/",
    },
    {
      image: article5,
      title: "ToIP: Qué es, ventajas y en qué se diferencia vs VoIP",
      textbody:
        "¿Quieres saber todo sobre el ToIP? En este artículo te explicamos a detalle toda la información que necesitas sobre esta tecnología.",
      link: "https://beexcc.com/blog/toip/",
    },
  ],
  contacts: {
    title: "Mejora los resultados de tus llamadas outbound utilizando nuestro Marcador Progresivo",
    textbody: "Queremos ayudarte a potenciar tus estrategias de comunicación telefónica. Déjanos saber cuáles son tus necesidades y nuestros agentes se pondrán en contacto contigo.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionRight: [
    {
      title: "Autoservicio en recalling",
      description: "<p>Ofrece opciones de autoservicio con IVR durante el proceso de recalling para que los clientes no contactados puedan resolver sus consultas rápidamente sin necesidad de un agente. Reduce la carga de tareas repetitivas de tus agentes.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Mejora tiempos de ocupación",
      description: "<p>Establece tiempos de espera máximos y mínimos que garanticen una distribución equitativa de las llamadas de todo tu equipo. Minimiza los tiempos muertos y maximiza la productividad para que los agentes estén activos y listos para interactuar con los clientes.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Algoritmos con IA",
      description: "<p>Nuestros algoritmos de llamadas con inteligencia artificial ajustan la marcación en tiempo real basándose en la disponibilidad de agentes y las tasas de respuesta, optimizando la asignación de llamadas para maximizar la productividad y la eficacia en el contacto con el cliente.</p>",
      image: ImageAccordionLeftFive
    },
    {
      title: "Integración con herramientas externas",
      description: "<p>Integra tus bases de datos como CRM o ERP, para gatillar tus campañas en simples pasos. Además, permite a los agentes tener información de los usuarios antes de gestionar la llamada, asegurando que cada interacción sea personalizada y eficiente.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Seguimiento con canales digitales",
      description: "<p>Integra el seguimiento de llamadas con canales digitales como WhatsApp, email o SMS, para una experiencia omnicanal sin interrupciones. Asegura que la comunicación con el cliente sea fluida, sin importar el canal que elijan, reforzando la relación y fidelidad hacia tu marca.</p>",
      image: ImageAccordionLeftFour
    },
  ],
  bannerVideo: {
    title: "Marcador predictivo vs progresivo",
    description: "<p>Existe una confusión entre el significado de ambos marcadores, sin embargo, tienen diferencias muy notables en sus casos de uso. <br /> <br /> Por eso, nuestro COO, Raúl Ballesteros, nos explica el significado de cada marcador, cuáles son sus ventajas y los principales usos.</p>",
    image: imageVideo
  },
  modules: [
    {
      tab: "Conversion",
      title: "Telemarketing",
      img: telemarketing,
      text:
        "Automatiza la marcación para que los agentes de telemarketing para que se concentren en hablar más y marcar menos. Incrementa las conexiones efectivas y reduce los tiempos muertos, mejorando directamente la productividad y las conversiones.",
      link: "/marketing-ventas/",
      colorTab: "#AFC9F7",
      background: "#d7e4fb"
    },
    {
      tab: "Sales",
      title: "Ventas",
      img: televentas,
      link: "/software-cobranzas/",
      text:
        "Aprovecha la información del cliente para personalizar cada llamada, reduciendo errores y tiempos de espera. Optimiza las operaciones y eleva las tasas de éxito en las ventas alcanzando los objetivos comerciales.",
      colorTab: "#90ECF2",
      background: "#d3f7fa"
    },
    {
      tab: "Payment",
      title: "Cobros",
      img: cobranzas,
      link: "/software-cobranzas/",
      text:
        "Prioriza las cuentas por importancia y probabilidad de pago, mejorando la eficiencia del equipo. Facilita un seguimiento efectivo, aumentando las recuperaciones sin deteriorar la relación con el cliente.",
      colorTab: "#FFAB99",
      background: "#FFD5CC"
    },
  ],
  testimonies: [
    {
      image: CulquiTes,
      title:
        "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
      textbody:
        "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
      name: "Paul Vera",
      post: "Jefe de Telemarketing de Culqi",
      link: "/casos-de-exito/culqi/",
      imgDesktop: culqiDesktop,
      imgMobile: culqiMobile,
      metrics: [
        {
          metric: "+65%",
          descripcion: "mejoró su contactabilidad con sus leads",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "aumentó sus ventas mensuales",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de bases",
          color: "#FFB800",
        },
      ],
    },
  ]
}

export default data
