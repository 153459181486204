import React from "react"

export const TitleUnderlineMarcadorProgresivoOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="356" height="10" viewBox="0 0 356 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M66.1751 9.2248C58.8192 8.27149 4.9312 9.12478 1.0155 8.83171C-2.1808 8.44571 2.95165 1.85682 5.92304 0.894092C6.96459 0.547922 8.17341 0.501179 10.9421 0.669545C15.332 0.983304 72.1101 0.58489 72.1101 0.58489C72.8591 0.790902 75.1104 0.111854 75.5227 0.36123C76.8211 0.944251 168.85 0.468517 176.698 0.463394C181.457 0.460487 177.692 0.841909 183.292 0.701979C195.226 0.424614 221.234 0.818189 230.278 0.486491C237.515 0.212242 239.076 0.204837 246.021 0.470053C253.119 0.710356 290.864 0.527046 295.18 0.0775525C296.236 -0.0460259 297.047 0.00124113 297.046 0.147481C297.046 0.179273 323.651 0.377763 323.773 0.492627C323.972 0.639543 324.782 0.712235 325.532 0.61305C326.925 0.465182 355.246 0.00823665 355.688 0.435748C357.076 1.66127 353.157 6.84907 349.864 8.10319C346.493 9.44606 328.017 7.86365 313.158 8.62703L243.989 8.47466L229.163 8.93294C227.466 8.48845 220.929 9.57259 216.985 8.77076C215.318 8.45353 203.538 8.36899 200.936 8.69078C198.67 8.93742 138.408 8.09046 132.224 8.95326C121.211 8.17827 76.5334 9.08196 66.1751 9.2248Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineMarcadorProgresivoTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="219" height="11" viewBox="0 0 219 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.3378 10.3671C36.8518 9.42353 3.9772 10.3482 1.58889 10.0603C-0.360443 9.67858 2.77925 3.08292 4.59317 2.11626C5.229 1.76871 5.96648 1.72037 7.65523 1.88506C10.3328 2.193 44.9698 1.71936 44.9698 1.71936C45.4264 1.92437 46.8007 1.24235 47.0519 1.49118C47.8432 2.07247 103.984 1.4748 108.772 1.45927C111.675 1.45006 109.378 1.83647 112.794 1.68912C120.075 1.39594 135.94 1.75505 141.457 1.41138C145.873 1.12754 146.825 1.11806 151.061 1.37408C155.391 1.60497 178.417 1.37165 181.05 0.916439C181.694 0.791461 182.189 0.837654 182.188 0.983894C182.188 1.01569 198.418 1.17892 198.492 1.29362C198.613 1.44028 199.108 1.51189 199.565 1.41171C200.415 1.262 217.693 0.767531 217.962 1.19445C218.807 2.41813 216.409 7.6111 214.398 8.86958C212.341 10.2169 201.071 8.65899 192.006 9.44206L149.811 9.38134L140.766 9.85927C139.731 9.41703 135.742 10.5098 133.337 9.71322C132.321 9.3982 125.134 9.32927 123.547 9.65451C122.165 9.90415 85.4039 9.13705 81.6303 10.008C74.913 9.24764 47.6569 10.2105 41.3378 10.3671Z" fill="#FFBA00" />
    </svg>
  </span>
)